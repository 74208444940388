import { Link } from '@remix-run/react'
import { cn } from '#app/utils/misc.tsx'

type LogoProps = {
	singleLine?: boolean
}

export function Logo({ singleLine }: LogoProps) {
	return (
		<Link
			to="/"
			className={cn(
				'group leading-snug',
				singleLine ? 'flex flex-row' : 'grid',
			)}
		>
			<span className="font-light transition group-hover:-translate-x-1">
				maldives
			</span>
			<span className="font-bold transition group-hover:translate-x-1">
				booking
			</span>
		</Link>
	)
}
